import { withRouter } from "next/router";
import Head from "next/head";
import React from "react";

const Header = (props: any) => {
  const { router }: { router: any } = props.router;
  const keywords: string = props.keywords;
  const description: string = props.description;
  const title: string = props.title;
  const canonicalUrl: string = props.canonicalUrl;
  return (
    <React.Fragment>
      <Head>
        <link
          rel="preconnect"
          href={process.env.PRODUCTION_URL}
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href={process.env.CDN_URL}
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href={process.env.API_CDN_URL}
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://google.com"
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://www.googletagmanager.com"
          crossOrigin="anonymous"
        />
        <link
          rel="preconect"
          href="https://www.google-analytics.com"
          crossOrigin="anonymous"
        />
        <link
          rel="canonical"
          href={
            canonicalUrl
              ? canonicalUrl
              : process.env.PRODUCTION_URL + router.asPath.substring(1)
          }
        />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="cache-control" content="Private" />
        <meta httpEquiv="Expires" content="31536000" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#0DB14B" />
        <meta
          name="Description"
          content={
            description
              ? description
              : "Sentient Productions is a full-feature production house that strives to bring out visceral cinema magic."
          }
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta
          name="keywords"
          content={
            keywords
              ? keywords
              : "sentient,motherlod,film,ad,production,camera,fx6,editing,video,animation"
          }
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta
          property="og:site_name"
          content={title ? title : "Sentient Productions"}
        />
        <meta
          property="og:title"
          content={title ? title : "Sentient Productions"}
        />
        <meta
          property="og:description"
          content={
            description
              ? description
              : "Sentient Productions is a full-feature production house that strives to bring out visceral cinema magic."
          }
        />
        <meta property="og:url" content={process.env.PRODUCTION_URL} />
        <meta
          property="og:image"
          content={process.env.CDN_URL + "images/icons/logo/og_512.jpg"}
        />
        <meta
          property="og:image:alt"
          content={process.env.CDN_URL + "images/icons/logo/og_512.jpg"}
        />
        <meta
          property="og:image:secure_url"
          content={process.env.CDN_URL + "images/icons/logo/og_512.jpg"}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta property="og:locale" content="en_GB" />
        <link
          rel="icon"
          href={process.env.CDN_URL + "images/icons/logo/favicon_48.png"}
        />
        <link
          rel="shortcut icon"
          type="image/png"
          href={process.env.CDN_URL + "images/icons/logo/favicon_48.png"}
        />
        <link
          rel="apple-touch-icon"
          href={process.env.CDN_URL + "images/icons/logo/favicon_192.png"}
        />
        <link rel="manifest" href={process.env.CDN_URL + "manifest.json"} />
        <title>{title ? title : "Sentient Productions"}</title>
      </Head>
    </React.Fragment>
  );
};

export default withRouter(Header);
