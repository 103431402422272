/* Common Imports */

import React from "react";
import Layout from "../components/layout-components/Layout";
import loadable from "@loadable/component";

/* Component Imports */

/* Eager Loaded Components */

import HeaderVideo from "../components/page-sections/index/HeaderVideo";

/* Lazy Loaded Components */

const AboutUs = loadable(() => import("../components/page-sections/index/AboutUs"));
const Services = loadable(() => import("../components/page-sections/index/Services"));
const CallToActionBanner = loadable(() =>import("../components/page-sections/index/CallToActionBanner"));
const ContactUs = loadable(() =>import("../components/page-sections/index/ContactUs"));
const FooterVideo = loadable(() =>import("../components/page-sections/index/FooterVideo"));

const Index = (props: any) => {

  /* State Definitions */

  const [intersectionStateCTAB, setIntersectionStateCTAB] = React.useState<boolean>(false);

  /* Callback Hooks */

  const intersectionStateCTABCallback = React.useCallback((intersectionState: boolean) => {
    setIntersectionStateCTAB(intersectionState);
  }, []);

  return (
    <React.Fragment>
      {/*

        The layout component is a wrapper for the entire page. It contains the layout header, footer, and the main content. It accepts a noContain prop that will determine if a child component should be contained or not. Each child is represented by it's index which can be passed as part of the noContain prop which is an array of indexes to not contain.

        Indexes are a mandatory prop for all children inside the layout component. They must each be a unique number.

        Other props that the layout component accepts are:

        => keywords (string): the keywords for the page
        => description (string): the description for the page
        => title (string): the title for the page
        => canonicalUrl (string): the canonical url for the page
        => pinnedFooter (boolean): determines if the footer should be pinned to the bottom of the window or not
        => noLayoutHeader (boolean): determines if the layout header should be rendered or not
        => noLayoutFooter (boolean): determines if the layout footer should be rendered or not

        */}
      <Layout noContain={[0, 3, 5]} intersectionStateCTAB={intersectionStateCTAB}>
        <HeaderVideo index={0} />
        <AboutUs index={1} />
        <Services index={2} />
        <CallToActionBanner
          index={3}
          intersectionState={intersectionStateCTABCallback}
        />
        <ContactUs index={4} />
        <FooterVideo index={5} />
      </Layout>
    </React.Fragment>
  );
};

export default Index;
