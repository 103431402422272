/* COmmon Imports */

import React from "react";
import { styled } from '@mui/system';

/* Component Imports */

import { Typography, Button, Link } from "@mui/material";

/* Icon Imports */

import { Call } from "@mui/icons-material";

/* Static Imports */

import HeaderVideoPoster from "../../../public/images/video-posters/header-video-poster.webp";

/* Type Definitions */

type CustomAttributesForHeaderVideoContainerComponent = {
    index?: number;
}

type CustomAttributesForTypographyComponent = {
    component?: React.Component | string;
}

/* Styled Component Definitions */

const SectionContainer = styled('div')<CustomAttributesForHeaderVideoContainerComponent>(({ theme }) => ({
    width:"100%",
    height: "102vh",
    position: "relative",
    margin: "-4rem 0rem 0rem 0rem",
}));

const HeaderVideoContainer = styled('div')(({ theme }) => ({
    width:"100%",
    height: "102vh",
    position: "absolute",
    top: 0,
    left: 0,
}));

const Video = styled('video')(({ theme }) => ({
    width:"100%",
    height: "102vh",
    objectFit: "cover",
}));

const Overlay = styled('div')(({ theme }) => ({
    width:"100%",
    height: "102vh",
    objectFit: "cover",
    backgroundColor: "rgba(0,0,0,0.6)",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap:"1.5rem",
    /* XXS breakpoint */
    [theme.breakpoints.only('xxs')]: {
      padding: "0rem 0.5rem 0rem 0.5rem",
  },
  /* XS breakpoint */
    [theme.breakpoints.only('xs')]: {
      padding: "0rem 1rem 0rem 1rem",
  },
  /* XSPLUS breakpoint */
    [theme.breakpoints.only("xsPlus")]: {
      padding: "0rem 1.5rem 0rem 1.5rem",
  },
    /* SM breakpoint */
    [theme.breakpoints.only("sm")]: {
      padding: "0rem 3rem 0rem 3rem",
  },
    /* MD breakpoint */
    [theme.breakpoints.only("md")]: {
      padding: "0rem 4rem 0rem 4rem",
  },
    /* LG breakpoint */
    [theme.breakpoints.only("lg")]: {
      padding: "0rem 4rem 0rem 4rem",
    },
    /* LGPLUS breakpoint */
    [theme.breakpoints.up('lgPlus')]: {
      padding: "0rem 5rem 0rem 5rem",
    },
    /* XL breakpoint */
    [theme.breakpoints.up("xl")]: {
      padding: "0rem 23rem 0rem 23rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up('xxl')]: {
      padding: "0rem 36rem 0rem 36rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up('xxxl')]: {
      padding: "0rem 72rem 0rem 72rem",
  },
}));

const Title = styled(Typography)<CustomAttributesForTypographyComponent>(({ theme }) => ({
    fontSize: "3rem",
    lineHeight: "4rem",
    fontWeight: "700",
    color: "#FFFFFF",
}));

const Subtitle = styled(Typography)<CustomAttributesForTypographyComponent>(({ theme }) => ({
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
    fontWeight: "400",
    color: "#FFFFFF",
}));

const CallToActionButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#FFFFFF",
    color: "#202020",
    margin: "1rem 0rem 0rem 0rem",
    "&:hover": {
        color: "#FFFFFF",
        background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    },
    "&:hover svg": {
        color: "#FFFFFF",
    },
}));

const CallToActionLink = styled(Link)(({ theme }) => ({
    textDecoration: "none",
}));

/* Default Export */

const HeaderVideo = ({ index }: { index: number; }) => {
    return (
        <React.Fragment>
            <SectionContainer index={index}>
                <Overlay>
                    <Title variant="h1" component="h1">
                        Video Production<br />Simplified
                    </Title>
                    <Subtitle variant="h6" component="span">
                        We help you build a strong brand value through compelling visuals.
                    </Subtitle>
                    <CallToActionLink
                        href="tel:+919731477555"
                        rel="noopener"
                        aria-label="Call Us"
                        title="Call Us"
                    >
                        <CallToActionButton size="large" variant="contained" startIcon={<Call />}>
                            Call Us
                        </CallToActionButton>
                    </CallToActionLink>
                </Overlay>
            <HeaderVideoContainer>
            <Video
                autoPlay={true}
                playsInline={true}
                muted={true}
                loop={true}
                poster={HeaderVideoPoster.src}
                preload="auto"
            >
            <source
                src={process.env.CDN_URL + "videos/header-video.webm"}
                type="video/webm"
            />
            <source
                src={process.env.CDN_URL + "videos/header-video.ogv"}
                type="video/ogg"
            />
            <source
                src={process.env.CDN_URL + "videos/header-video.mp4"}
                type="video/mp4"
            />
            Your browser does not support HTML5 video.
          </Video>
                </HeaderVideoContainer>
            </SectionContainer>
        </React.Fragment>
    );
};

export default HeaderVideo;