/* React Imports */

import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, NextRouter } from "next/router";

/* Component Imports */

import Header from "./Header";
import Container from "./Container";
import LayoutHeader from "./LayoutHeader";
import LayoutFooter from "./LayoutFooter";

const Layout = ({ keywords, title, description, canonicalUrl, pinnedFooter, noLayoutHeader, noLayoutFooter, noContain, children, router, intersectionStateCTAB }: { keywords?: string; title?: string; description?: string; canonicalUrl?: string; pinnedFooter?: boolean; noLayoutHeader?: boolean; noLayoutFooter?: boolean; noContain?: Array<number>; children?: React.ReactNode; router: NextRouter; intersectionStateCTAB: boolean; }) => {
  return (
    <React.Fragment>
      <div id="rootDiv">
        <Header
          keywords={
            keywords
              ? keywords
              : "sentient,motherlod,film,ad,production,camera,fx6,editing,video,animation"
          }
          description={
            description
              ? description
              : "Sentient Productions is a full-feature production house that strives to bring out visceral cinema magic."
          }
          title={title ? title : "Sentient Productions"}
          canonicalUrl={
            canonicalUrl
              ? canonicalUrl
              : process.env.PRODUCTION_URL + router.asPath.substring(1)
          }
        />
        <Helmet>
          <html />
        </Helmet>
        {noLayoutHeader ? undefined : <LayoutHeader intersectionStateCTAB={intersectionStateCTAB} />}
        {children && noContain ? (
          React.Children.map(children, (child, key) =>
            noContain?.includes(key) ? (
              <React.Fragment>
                <Container key={key} noContain={noContain?.length > 0} pinnedFooter={pinnedFooter}>
                  {child}
                </Container>
              </React.Fragment>
            ) : (
              <React.Fragment>
                  <Container key={key} noContain={false} pinnedFooter={pinnedFooter}>{child}</Container>
              </React.Fragment>
            )
          )
        ) : (
          <React.Fragment>
              <Container noContain={false} pinnedFooter={pinnedFooter}>{children}</Container>
          </React.Fragment>
        )}
        {noLayoutFooter ? undefined : <LayoutFooter variant={pinnedFooter ? "pinned" : undefined} />}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);