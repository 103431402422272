import React, { ReactElement } from "react";
import { styled } from '@mui/system';

/* Styled Container Component */

const ContainerComponent = styled('div', { shouldForwardProp: (prop) => prop !== "pinnedFooter" })<{ pinnedFooter ?: boolean | undefined; }>(({ theme, pinnedFooter }) => ({
  /* XXS breakpoint */
    [theme.breakpoints.only('xxs')]: {
      padding: pinnedFooter ? "0rem 0.5rem 9rem 0.5rem" : "0rem 0.5rem 0rem 0.5rem",
  },
  /* XS breakpoint */
    [theme.breakpoints.only('xs')]: {
      padding: pinnedFooter ? "0rem 1rem 9rem 1rem" : "0rem 1rem 0rem 1rem",
  },
  /* XSPLUS breakpoint */
    [theme.breakpoints.only("xsPlus")]: {
      padding: pinnedFooter ? "0rem 1.5rem 9rem 1.5rem" : "0rem 1.5rem 0rem 1.5rem",
  },
    /* SM breakpoint */
    [theme.breakpoints.only("sm")]: {
      padding: pinnedFooter ? "0rem 3rem 9rem 3rem" : "0rem 3rem 0rem 3rem",
  },
    /* MD breakpoint */
    [theme.breakpoints.only("md")]: {
      padding: pinnedFooter ? "0rem 4rem 4rem 4rem" : "0rem 4rem 0rem 4rem",
  },
    /* LG breakpoint */
    [theme.breakpoints.only("lg")]: {
      padding: pinnedFooter ? "0rem 4rem 4rem 4rem" : "0rem 4rem 0rem 4rem",
    },
    /* LGPLUS breakpoint */
    [theme.breakpoints.up('lgPlus')]: {
      padding: pinnedFooter ? "0rem 5rem 4rem 5rem" : "0rem 5rem 0rem 5rem",
    },
    /* XL breakpoint */
    [theme.breakpoints.up("xl")]: {
      padding: pinnedFooter ? "0rem 23rem 4rem 23rem" : "0rem 23rem 0rem 23rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up('xxl')]: {
      padding: pinnedFooter ? "0rem 36rem 4rem 36rem" : "0rem 36rem 0rem 36rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up('xxxl')]: {
      padding: pinnedFooter ? "0rem 72rem 4rem 72rem" : "0rem 72rem 0rem 72rem",
  },
}));

const ContainerComponentNoContain = styled('div', { shouldForwardProp: (prop) => prop !== "pinnedFooter" })<{ pinnedFooter ?: boolean | undefined; }>(({ theme, pinnedFooter }) => ({
  /* XXS breakpoint */
    [theme.breakpoints.only('xxs')]: {
      padding: pinnedFooter ? "0rem 0rem 9rem 0rem" : "0rem 0rem 0rem 0rem",
  },
  /* XS breakpoint */
    [theme.breakpoints.only('xs')]: {
      padding: pinnedFooter ? "0rem 0rem 9rem 0rem" : "0rem 0rem 0rem 0rem",
  },
  /* XSPLUS breakpoint */
    [theme.breakpoints.only("xsPlus")]: {
      padding: pinnedFooter ? "0rem 0rem 9rem 0rem" : "0rem 0rem 0rem 0rem",
  },
    /* SM breakpoint */
    [theme.breakpoints.only("sm")]: {
      padding: pinnedFooter ? "0rem 0rem 9rem 0rem" : "0rem 0rem 0rem 0rem",
  },
    /* MD breakpoint */
    [theme.breakpoints.only("md")]: {
      padding: pinnedFooter ? "0rem 0rem 4rem 0rem" : "0rem 0rem 0rem 0rem",
  },
    /* LG breakpoint */
    [theme.breakpoints.only("lg")]: {
      padding: pinnedFooter ? "0rem 0rem 4rem 0rem" : "0rem 0rem 0rem 0rem",
    },
    /* LGPLUS breakpoint */
    [theme.breakpoints.up('lgPlus')]: {
      padding: pinnedFooter ? "0rem 0rem 4rem 0rem" : "0rem 0rem 0rem 0rem",
    },
    /* XL breakpoint */
    [theme.breakpoints.up("xl")]: {
      padding: pinnedFooter ? "0rem 0rem 4rem 0rem" : "0rem 0rem 0rem 0rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up('xxl')]: {
      padding: pinnedFooter ? "0rem 0rem 4rem 0rem" : "0rem 0rem 0rem 0rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up('xxxl')]: {
      padding: pinnedFooter ? "0rem 0rem 4rem 0rem" : "0rem 0rem 0rem 0rem",
  },
}));

/* Let's extend React.ReactElement to accept an index prop */

interface ReactElementCustom extends React.ReactElement {
  index: number;
};

/* Default Export */

const Container = (props: { noContain: boolean; children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; pinnedFooter: boolean | undefined; }) => {
  return (
    <React.Fragment>
      {React.Children.map(props.children, (child: ReactElementCustom) => {
        if (React.isValidElement(child)) {
          return props.noContain ? React.cloneElement(<ContainerComponentNoContain key={child.key ? child.key : 0} pinnedFooter={props.pinnedFooter}>{child}</ContainerComponentNoContain>, (() => ({ "layout-child-index": child.props.index, "is-contained": "false" }))()) : React.cloneElement(<ContainerComponent key={child.key ? child.key : 0} pinnedFooter={props.pinnedFooter}>{child}</ContainerComponent>, (()=>({"layout-child-index": child.props.index,"is-contained":"true"}))());
    }
    return child;
          }
        )}
    </React.Fragment>
  );
};

export default Container;
