/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import {
  Link,
  SvgIcon,
  Typography,
  SwipeableDrawer,
  IconButton,
  AppBar,
  Toolbar,
  Slide,
} from "@mui/material";

/* Icon Imports */

import { Menu } from "@mui/icons-material";

/* Static Imports */

import Logo from "../../public/images/logo/logo.svg";
import LogoWhite from "../../public/images/logo/logo-white.svg";
import LogoSwipeableDrawer from "../../public/images/logo/logo-swipeable-drawer.svg";

/* Type Definitions */

type CustomAttributesForComponent = {
  component?: React.Component | string;
};

/* Styled Component Definitions */

const SectionContainer = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  height: "4rem",
  padding: "0rem 1rem 0rem 1rem",
  [theme.breakpoints.down("sm")]: {
    padding:"0rem 0.5rem 0rem 0.5rem",
  },
}));

const MotherlodLogo = styled(SvgIcon)<CustomAttributesForComponent>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    /*
      This negative margin takes the space occupied by the hamburger menu icon into account.
      This perfectly centers the logo on the navbar but makes it appear closer to the hamburger menu icon.
     */
    margin:"0rem 0rem 0rem -1.25rem",
  },
}));
const SwipeableDrawerMotherlodLogo = styled(SvgIcon)<CustomAttributesForComponent>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

const LogoLink = styled(Link)(({ theme }) => ({
  width: "9.5rem",
  height: "2rem",
}));

const SwipeableDrawerLogoLink = styled(Link)(({ theme }) => ({
  width: "13.5rem",
  height: "2.875rem",
}));

const PageLinkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "2.5rem",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const PageLink = styled(Link)(({ theme }) => ({
  width: "auto",
  height: "2rem",
  textDecoration: "none",
}));

const PageLinkTypography = styled(Typography)<CustomAttributesForComponent>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "auto",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  fontWeight: "400",
}));

const SwipeableDrawerPageLink = styled(Link)(({ theme }) => ({
  width: "100%",
  height: "1.5rem",
  textDecoration: "none",
}));

const SwipeableDrawerPageLinkTypography = styled(Typography)<CustomAttributesForComponent>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  width: "100%",
  height: "auto",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  fontWeight: "400",
  color:"#202020"
}));

const HamburgerMenuIcon = styled(IconButton)(({ theme }) => ({
  height: "2rem",
  width: "2rem",
  padding:"0rem 0rem 0rem 0rem",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const Spacer = styled("span")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const SwipeableDrawerContainer = styled("div")(({ theme }) => ({
  width: "16rem",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "1.25rem 1.25rem 1.25rem 1.25rem",
  gap:"2rem"
}));

/* Default Export */

const LayoutHeader = ({ intersectionStateCTAB }: {intersectionStateCTAB: boolean}) => {

  /* State Definitions */

  const [isSwipeableDrawerOpen, setIsSwipeableDrawerOpen] = React.useState<boolean>(false);
  const [scrollY, setScrollY] = React.useState<number>(0);
  const [negativeScroll, setNegativeScroll] = React.useState<boolean>(true);
  const [inverseColors, setInverseColors] = React.useState<boolean>(true);

  /* Effect Definitions */

  React.useEffect(() => {

    /* First let's define an event listener */

    const handleScroll = () => {
    if (window.scrollY < scrollY) {
      setNegativeScroll(true);
    }
    else {
      setNegativeScroll(false);
    }
      if (window.scrollY >= (window.innerHeight - 64 + ((window.innerHeight / 100) * 2)) && !intersectionStateCTAB && !(((window.document.body.scrollHeight - window.scrollY) - 64) <= window.innerHeight)) {
        setInverseColors(false);
      }
      else {
        setInverseColors(true);
      }
    setScrollY(window.scrollY);
    };

    /* Now we can add the event listener */

    window.addEventListener("scroll", handleScroll, {passive: true});
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY, intersectionStateCTAB]);

  /*
    User Agent Tests

    (Why?) https://mui.com/components/drawers/#swipeable

  */

  const isiOS: boolean = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor="left"
        open={isSwipeableDrawerOpen}
        onOpen={() => { setIsSwipeableDrawerOpen(true) }}
        onClose={() => { setIsSwipeableDrawerOpen(false) }}
        disableBackdropTransition={!isiOS}
        disableDiscovery={isiOS}
      >
        <SwipeableDrawerContainer>
          <SwipeableDrawerLogoLink
            href="/"
            rel="noopener"
            aria-label="Motherlod"
            title="Home"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            setIsSwipeableDrawerOpen(false);
            }}
          >
          <SwipeableDrawerMotherlodLogo
            component={LogoSwipeableDrawer}
            viewBox={"0 0 " + LogoSwipeableDrawer().props.width + " " + LogoSwipeableDrawer().props.height}
            inheritViewBox={false}
          />
          </SwipeableDrawerLogoLink>
          <SwipeableDrawerPageLink
            href="#rootDiv"
            rel="noopener"
            aria-label="Home"
            title="Home"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            setIsSwipeableDrawerOpen(false);
            }}
          >
            <SwipeableDrawerPageLinkTypography variant="h6" component="span">
              Home
          </SwipeableDrawerPageLinkTypography>
          </SwipeableDrawerPageLink>
          <SwipeableDrawerPageLink
            href="#about_us"
            rel="noopener"
            aria-label="About Us"
            title="About Us"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            setIsSwipeableDrawerOpen(false);
            }}
          >
            <SwipeableDrawerPageLinkTypography variant="h6" component="span">
              About Us
          </SwipeableDrawerPageLinkTypography>
          </SwipeableDrawerPageLink>
          <SwipeableDrawerPageLink
            href="#services"
            rel="noopener"
            aria-label="Services"
            title="Services"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            setIsSwipeableDrawerOpen(false);
            }}
          >
            <SwipeableDrawerPageLinkTypography variant="h6" component="span">
              Services
          </SwipeableDrawerPageLinkTypography>
          </SwipeableDrawerPageLink>
          <SwipeableDrawerPageLink
            href="#contact_us"
            rel="noopener"
            aria-label="Contact Us"
            title="Contact Us"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            setIsSwipeableDrawerOpen(false);
            }}
          >
            <SwipeableDrawerPageLinkTypography variant="h6" component="span">
              Contact Us
          </SwipeableDrawerPageLinkTypography>
        </SwipeableDrawerPageLink>
        </SwipeableDrawerContainer>
      </SwipeableDrawer>
      <Slide appear={false} direction="down" in={negativeScroll}>
        <AppBar position="fixed" color="transparent" elevation={scrollY === 0 ? 0 : 3}>
      <SectionContainer sx={{backdropFilter: scrollY !== 0 ? "blur(1rem)": ""}}>
        <HamburgerMenuIcon
          aria-label="Menu"
          onClick={
            (event: React.MouseEvent<HTMLButtonElement>) => {
              setIsSwipeableDrawerOpen(!isSwipeableDrawerOpen);
            }
          }
        >
          <Menu sx={{ color: inverseColors ? "#FFFFFF" : "#202020" }} />
        </HamburgerMenuIcon>
        <LogoLink href="/" rel="noopener" aria-label="Motherlod" title="Home">
          <MotherlodLogo
            component={inverseColors ? LogoWhite : Logo}
            viewBox={"0 0 " + (inverseColors ? Logo() : LogoWhite()).props.width + " " + (inverseColors ? Logo() : LogoWhite()).props.height}
            inheritViewBox={false}
          />
          </LogoLink>
        <PageLinkContainer>
        <PageLink href="#rootDiv" rel="noopener" aria-label="Home" title="Home">
            <PageLinkTypography variant="h6" component="span" sx={{ color: inverseColors ? "#FFFFFF" : "#202020" }}>
              Home
          </PageLinkTypography>
          </PageLink>
          <PageLink href="#about_us" rel="noopener" aria-label="About Us" title="About Us">
            <PageLinkTypography variant="h6" component="span" sx={{ color: inverseColors ? "#FFFFFF" : "#202020" }}>
              About Us
          </PageLinkTypography>
          </PageLink>
          <PageLink href="#services" rel="noopener" aria-label="Services" title="Services">
            <PageLinkTypography variant="h6" component="span" sx={{ color: inverseColors ? "#FFFFFF" : "#202020" }}>
              Services
          </PageLinkTypography>
          </PageLink>
          <PageLink href="#contact_us" rel="noopener" aria-label="Contact Us" title="Contact Us">
            <PageLinkTypography variant="h6" component="span" sx={{ color: inverseColors ? "#FFFFFF" : "#202020" }}>
              Contact Us
          </PageLinkTypography>
        </PageLink>
        </PageLinkContainer>
        <Spacer />
        </SectionContainer>
        </AppBar>
      </Slide>
      <Toolbar />
    </React.Fragment>
  );
};

export default LayoutHeader;