/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { SvgIcon, Link, Typography } from "@mui/material";

/* Static Imports */

import Logo from "../../public/images/logo/logo-footer.svg";
import LogoWhite from "../../public/images/logo/logo-white-footer.svg";
import Facebook from "../../public/images/icons/social/facebook.svg";
import Twitter from "../../public/images/icons/social/twitter.svg";
import Instagram from "../../public/images/icons/social/instagram.svg";
import Youtube from "../../public/images/icons/social/youtube.svg";
import Linkedin from "../../public/images/icons/social/linkedin.svg";

/* Type Definitions */

type CustomAttributesForComponent = {
  component?: React.Component | string;
  copyrightTextColor?: string;
};

/* Styled Component Definitions */

const LayoutFooterComponent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height:"4rem",
  backgroundColor: "transparent",
  flexWrap: "wrap",
  gap: "2rem",
  position: "relative",
  zIndex: 1,
  /* XXS breakpoint */
    [theme.breakpoints.only('xxs')]: {
      padding: "1rem 0.5rem 1rem 0.5rem",
      height: "9rem",
      gap: "0.5rem",
      justifyContent: "center",
  },
  /* XS breakpoint */
    [theme.breakpoints.only('xs')]: {
      padding: "1rem 1rem 1rem 1rem",
      height: "9rem",
      gap: "0.5rem",
      justifyContent: "center",
  },
  /* XSPLUS breakpoint */
    [theme.breakpoints.only("xsPlus")]: {
      padding: "1rem 1.5rem 1rem 1.5rem",
      height: "9rem",
      gap: "0.5rem",
      justifyContent: "center",
  },
    /* SM breakpoint */
    [theme.breakpoints.only("sm")]: {
      padding: "1rem 3rem 1rem 3rem",
      height: "9rem",
      gap: "0.5rem",
      justifyContent: "center",
  },
    /* MD breakpoint */
    [theme.breakpoints.only("md")]: {
      padding: "0rem 4rem 0rem 4rem",
  },
    /* LG breakpoint */
    [theme.breakpoints.only("lg")]: {
      padding: "0rem 4rem 0rem 4rem",
    },
    /* LGPLUS breakpoint */
    [theme.breakpoints.up('lgPlus')]: {
      padding: "0rem 5rem 0rem 5rem",
    },
    /* XL breakpoint */
    [theme.breakpoints.up("xl")]: {
      padding: "0rem 23rem 0rem 23rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up('xxl')]: {
      padding: "0rem 36rem 0rem 36rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up('xxxl')]: {
      padding: "0rem 72rem 0rem 72rem",
  },
}));

const LayoutFooterComponentPinned = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height:"4rem",
  backgroundColor: "#F8F8F8",
  flexWrap: "wrap",
  gap: "2rem",
  position: "fixed",
  bottom: 0,
  zIndex: 1,
  /* XXS breakpoint */
    [theme.breakpoints.only('xxs')]: {
      padding: "1rem 0.5rem 1rem 0.5rem",
      height: "9rem",
      gap: "0.5rem",
      justifyContent: "center",
  },
  /* XS breakpoint */
    [theme.breakpoints.only('xs')]: {
      padding: "1rem 1rem 1rem 1rem",
      height: "9rem",
      gap: "0.5rem",
      justifyContent: "center",
  },
  /* XSPLUS breakpoint */
    [theme.breakpoints.only("xsPlus")]: {
      padding: "1rem 1.5rem 1rem 1.5rem",
      height: "9rem",
      gap: "0.5rem",
      justifyContent: "center",
  },
    /* SM breakpoint */
    [theme.breakpoints.only("sm")]: {
      padding: "1rem 3rem 1rem 3rem",
      height: "9rem",
      gap: "0.5rem",
      justifyContent: "center",
  },
    /* MD breakpoint */
    [theme.breakpoints.only("md")]: {
      padding: "0rem 4rem 0rem 4rem",
  },
    /* LG breakpoint */
    [theme.breakpoints.only("lg")]: {
      padding: "0rem 4rem 0rem 4rem",
    },
    /* LGPLUS breakpoint */
    [theme.breakpoints.up('lgPlus')]: {
      padding: "0rem 5rem 0rem 5rem",
    },
    /* XL breakpoint */
    [theme.breakpoints.up("xl")]: {
      padding: "0rem 23rem 0rem 23rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up('xxl')]: {
      padding: "0rem 36rem 0rem 36rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up('xxxl')]: {
      padding: "0rem 72rem 0rem 72rem",
  },
}));

const LogoLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "calc(33.33% - 2rem)",
  [theme.breakpoints.down('md')]: {
    justifyContent: "center",
    width:"calc(100% - 1rem)",
  },
}));

const MotherlodLogo = styled(SvgIcon)<CustomAttributesForComponent>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  [theme.breakpoints.down('md')]: {
    justifyContent: "center",
  },
  alignItems: "center",
  width: "9.5rem",
  height: "2rem",
}));

const CopyrightText = styled(Typography, { shouldForwardProp: (prop) => prop !== "copyrightTextColor" })<CustomAttributesForComponent>(({ theme, copyrightTextColor }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "calc(33.33% - 1rem)",
  [theme.breakpoints.down('md')]: {
    width:"calc(100% - 2rem)",
  },
  fontSize: "1rem",
  lineHeight: "1rem",
  fontWeight: "500",
  color: copyrightTextColor,
  textAlign: "center",
  height: "2rem",
  [theme.breakpoints.down(theme.breakpoints.values.xs + 30)]: {
    display:"none"
  },
}));

const CopyrightTextGalaxyFold = styled(Typography, { shouldForwardProp: (prop) => prop !== "copyrightTextColor" })<CustomAttributesForComponent>(({ theme, copyrightTextColor }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "calc(33.33% - 1rem)",
  [theme.breakpoints.down('md')]: {
    width:"calc(100% - 2rem)",
  },
  fontSize: "1rem",
  lineHeight: "1rem",
  fontWeight: "500",
  color: copyrightTextColor,
  textAlign: "center",
  height: "2rem",
  [theme.breakpoints.up(theme.breakpoints.values.xs + 30)]: {
    display:"none"
  },
}));

const SocialLinks = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "calc(33.33% - 2rem)",
  [theme.breakpoints.down('md')]: {
    justifyContent: "center",
    width:"calc(100% - 1rem)",
  },
  gap:"1rem",
}));

const SocialLink = styled(Link)<CustomAttributesForComponent>(({ theme }) => ({
  textDecoration: "none",
}));

const SocialIcon = styled(SvgIcon)<CustomAttributesForComponent>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

/* Default Export */

const LayoutFooter = ({ variant }: { variant: string | undefined; }) => {

  /* State Definitions */

  const [inverseColors, setInverseColors] = React.useState<boolean>(true);

  return (
    <React.Fragment>
      {variant === "pinned" ? (
        <LayoutFooterComponentPinned>
          <LogoLink href="/" rel="noopener" aria-label="Motherlod" title="Home">
              <MotherlodLogo
                component={inverseColors ? LogoWhite : Logo}
                viewBox={"0 0 " + (inverseColors ? LogoWhite() : Logo()).props.width + " " + (inverseColors ? LogoWhite() : Logo()).props.height}
                inheritViewBox={false}
              />
          </LogoLink>
          <CopyrightText variant="body2" component="span" copyrightTextColor={inverseColors ? "#FFFFFF" : "#202020"}>
            &copy; {new Date().getFullYear()} Sentient Enterprises LLP<br/>All rights reserved
            </CopyrightText>
            <CopyrightTextGalaxyFold variant="body2" component="span" copyrightTextColor={inverseColors ? "#FFFFFF" : "#202020"}>
            &copy; {new Date().getFullYear()} Sentient Enterprises LLP. All rights reserved.
          </CopyrightTextGalaxyFold>
          <SocialLinks>
            <SocialLink href="//facebook.com/motherlodprod" rel="noopener" aria-label="Facebook" title="Follow us on Facebook!" target="_blank">
              <SocialIcon
                component={Facebook}
                viewBox={"0 0 " + Facebook().props.width + " " + Facebook().props.height}
                width={Facebook().props.width}
                height={Facebook().props.height}
                inheritViewBox={false}
              />
              </SocialLink>
              <SocialLink href="//linkedin.com/company/motherlodprod" rel="noopener" aria-label="LinkedIn" title="Follow us on LinkedIn!" target="_blank">
              <SocialIcon
                component={Linkedin}
                viewBox={"0 0 " + Linkedin().props.width + " " + Linkedin().props.height}
                width={Linkedin().props.width}
                height={Linkedin().props.height}
                inheritViewBox={false}
              />
              </SocialLink>
              <SocialLink href="//instagram.com/motherlodprod" rel="noopener" aria-label="Instagram" title="Follow us on Instagram!" target="_blank">
              <SocialIcon
                component={Instagram}
                viewBox={"0 0 " + Instagram().props.width + " " + Instagram().props.height}
                width={Instagram().props.width}
                height={Instagram().props.height}
                inheritViewBox={false}
              />
              </SocialLink>
              <SocialLink href="//twitter.com/motherlodprod" rel="noopener" aria-label="Twitter" title="Follow us on Twitter!" target="_blank">
              <SocialIcon
                component={Twitter}
                viewBox={"0 0 " + Twitter().props.width + " " + Twitter().props.height}
                width={Twitter().props.width}
                height={Twitter().props.height}
                inheritViewBox={false}
              />
              </SocialLink>
              <SocialLink href="//youtube.com/channel/UCiNg-hNbvABoz3xLrq872zg" rel="noopener" aria-label="Youtube" title="Follow us on Youtube!" target="_blank">
              <SocialIcon
                component={Youtube}
                viewBox={"0 0 " + Youtube().props.width + " " + Youtube().props.height}
                width={Youtube().props.width}
                height={Youtube().props.height}
                inheritViewBox={false}
              />
            </SocialLink>
          </SocialLinks>
        </LayoutFooterComponentPinned>
      ) : (
        <LayoutFooterComponent>
          <LogoLink href="/" rel="noopener" aria-label="Motherlod" title="Home">
              <MotherlodLogo
                component={inverseColors ? LogoWhite : Logo}
                viewBox={"0 0 " + (inverseColors ? LogoWhite() : Logo()).props.width + " " + (inverseColors ? LogoWhite() : Logo()).props.height}
                inheritViewBox={false}
              />
          </LogoLink>
            <CopyrightText variant="body2" component="span" copyrightTextColor={inverseColors ? "#FFFFFF" : "#202020"}>
            &copy; {new Date().getFullYear()} Sentient Enterprises LLP<br/>All rights reserved
            </CopyrightText>
            <CopyrightTextGalaxyFold variant="body2" component="span" copyrightTextColor={inverseColors ? "#FFFFFF" : "#202020"}>
            &copy; {new Date().getFullYear()} Sentient Enterprises LLP. All rights reserved.
          </CopyrightTextGalaxyFold>
          <SocialLinks>
            <SocialLink href="//facebook.com/motherlodprod" rel="noopener" aria-label="Facebook" title="Follow us on Facebook!" target="_blank">
              <SocialIcon
                component={Facebook}
                viewBox={"0 0 " + Facebook().props.width + " " + Facebook().props.height}
                width={Facebook().props.width}
                height={Facebook().props.height}
                inheritViewBox={false}
              />
              </SocialLink>
              <SocialLink href="//linkedin.com/company/motherlodprod" rel="noopener" aria-label="LinkedIn" title="Follow us on LinkedIn!" target="_blank">
              <SocialIcon
                component={Linkedin}
                viewBox={"0 0 " + Linkedin().props.width + " " + Linkedin().props.height}
                width={Linkedin().props.width}
                height={Linkedin().props.height}
                inheritViewBox={false}
              />
              </SocialLink>
              <SocialLink href="//instagram.com/motherlodprod" rel="noopener" aria-label="Instagram" title="Follow us on Instagram!" target="_blank">
              <SocialIcon
                component={Instagram}
                viewBox={"0 0 " + Instagram().props.width + " " + Instagram().props.height}
                width={Instagram().props.width}
                height={Instagram().props.height}
                inheritViewBox={false}
              />
              </SocialLink>
              <SocialLink href="//twitter.com/motherlodprod" rel="noopener" aria-label="Twitter" title="Follow us on Twitter!" target="_blank">
              <SocialIcon
                component={Twitter}
                viewBox={"0 0 " + Twitter().props.width + " " + Twitter().props.height}
                width={Twitter().props.width}
                height={Twitter().props.height}
                inheritViewBox={false}
              />
              </SocialLink>
              <SocialLink href="//youtube.com/channel/UCiNg-hNbvABoz3xLrq872zg" rel="noopener" aria-label="Youtube" title="Follow us on Youtube!" target="_blank">
              <SocialIcon
                component={Youtube}
                viewBox={"0 0 " + Youtube().props.width + " " + Youtube().props.height}
                width={Youtube().props.width}
                height={Youtube().props.height}
                inheritViewBox={false}
              />
            </SocialLink>
          </SocialLinks>
        </LayoutFooterComponent>
      )}
    </React.Fragment>
  );
};

export default LayoutFooter;